var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    directives: [
      { name: "show", rawName: "v-show", value: _vm.show, expression: "show" },
    ],
    staticClass: "mask",
    on: {
      click: function ($event) {
        $event.preventDefault()
        return _vm.onClickMask.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }