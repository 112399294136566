<template>
  <div class="mask" v-show="show" @click.prevent="onClickMask"></div>
</template>

<script>
export default {
  name: "opacity_mask",
  props: {
    value: Boolean
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    // 点击遮罩
    onClickMask() {
      this.$emit("on-click-mask");
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.show = val;
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
</style>
