<template>
  <div class="page">
    <!-- S 背景图 -->
    <div class="img-bg"></div>
    <!-- E 背景图 -->

    <h3 class="title">Hi，请先绑定手机号</h3>

    <!-- S 表单 -->
    <div class="form">
      <div class="form-group border-bottom-1px">
        <label for="mobile" class="form-label">
          <i class="icon icon-mobile"></i>
        </label>
        <input
            type="text"
            pattern="\d*"
            required
            v-model="mobile"
            id="mobile"
            class="form-control"
            maxlength="11"
            placeholder="请输入手机号码"
        >
      </div>
      <div class="form-group border-bottom-1px">
        <label for="code" class="form-label">
          <i class="icon icon-img-code"></i>
        </label>
        <input
            type="text"
            required
            v-model="code"
            id="code"
            class="form-control"
            maxlength="6"
            placeholder="请输入验证码"
        >
        <div class="img-box" @click.prevent="getRandomImgCode">
          <img :src="img_code" class="img-code">
        </div>
      </div>
      <div class="form-group border-bottom-1px">
        <label for="code" class="form-label">
          <i class="icon icon-sms-code"></i>
        </label>
        <input
            type="text"
            required
            v-model="sms_code"
            id="code"
            class="form-control"
            maxlength="6"
            placeholder="请输入短信验证码"
        >
        <div class="btn send-btn flex-center" v-show="code_bool" @click.prevent="getSmsCode">发送验证码</div>
        <div class="countdown flex-center" v-show="!code_bool">{{count}}s</div>
      </div>
      <div class="form-group border-bottom-1px">
        <label for="new_password" class="form-label">
          <i class="icon icon-password"></i>
        </label>
        <input
            type="password"
            required
            v-model="new_password"
            id="new_password"
            class="form-control"
            placeholder="请设置你的登录密码"
        >
      </div>
      <div class="form-group border-bottom-1px">
        <label for="confirm_password" class="form-label">
          <i class="icon icon-password"></i>
        </label>
        <input
            type="password"
            required
            v-model="confirm_password"
            id="confirm_password"
            class="form-control"
            placeholder="请再次输入登录密码"
        >
      </div>
    </div>
    <!-- E 表单 -->


    <!-- S 注册按钮 -->
    <div class="btn submit-btn flex-center" @click="submit">立即绑定</div>
    <!-- E 注册按钮 -->

    <!-- S 提示弹窗 -->
    <alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import OpacityMask from "@/components/common/OpacityMask";
import { getImgCode, sendVerifyCode } from "@/services";
import {getTagArticleDetail,bindMobile} from "@/services/my";
import { toolTip, inputMixins } from "@/mixins";
import { debounce } from "@/utils/debounce";
import md5 from "js-md5";

let base_code = "";
const COMPUTED_TIME = 60;
export default {
  name: "Register",
  components: {
    AlertTip,
    OpacityMask
  },
  mixins: [toolTip, inputMixins],
  data() {
    return {
      mobile: "", // 手机号码
      code: "", // 验证码
      img_code: "", // 图形验证码
      sms_code: "", // 短信验证码
      code_bool: true, // 控制显示获取验证码
      count: "", // 倒计时
      timer: null, // 计时器
      title:'',
      contents:'',
      new_password: "", // 新密码
      confirm_password: "" // 再次输入密码
    };
  },
  created() {
    this.loadImgCode();
    this.getTagArticleDetail()
  },
  methods: {
    // 获取图形验证码
    async loadImgCode() {
      const res = await getImgCode();
      if (res.code === 0) {
        this.img_code = res.data.url;
        base_code = res.data.url;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.show_layer("cancel", res.error_msg);
        }
      }
    },
    // 获取用户隐私协议
    async getTagArticleDetail() {
      const res = await getTagArticleDetail('user_hide');
      if (res.code === 0) {
        this.contents = res.data.contents;
        this.title = res.data.title;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.show_layer("cancel", res.error_msg);
        }
      }
    },
    // 切换图形验证码
    getRandomImgCode: debounce(function() {
      this.img_code = base_code + "&" + Math.random();
    }, 300),
    // 同意协议？
    toggleAgree() {
      this.hasAgree = !this.hasAgree;
    },
    // 获取短信验证码
    getSmsCode: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }

      const res = await sendVerifyCode(mobile, "register", code);
      if (res.code === 0) {
        this.show_layer("success", "验证码已发送，请注意查收");
        this.count = COMPUTED_TIME;
        this.code_bool = false;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
        this.show_layer("cancel", res.msg);
      }
    }, 300),
    //  显示协议
    openProtocolAlert() {
      this.show_mask = true;
      this.show_protocol = true;
    },
    // 关闭弹窗
    closeProtocol() {
      this.show_mask = false;
      this.show_protocol = false;
    },
    // 提交表单
    submit: debounce(async function() {
      let mobile = this.mobile.replace(/\s+/g, ""),
        code = this.code.replace(/\s+/g, ""),
        sms_code = this.sms_code.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      let new_password = this.new_password.replace(/\s+/g, ""),
        confirm_password = this.confirm_password.replace(/\s+/g, "");
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (!sms_code) {
        this.show_layer("cancel", "请输入验证码");
        return;
      }
      if (!new_password) {
        this.show_layer("cancel", "请输入账号密码");
        return;
      }
      if (!confirm_password) {
        this.show_layer("cancel", "请输入确认密码");
        return;
      }
      if (new_password != confirm_password) {
        this.show_layer("cancel", "两次输入不一致");
        return;
      }
      try {
        const res = await bindMobile(mobile, sms_code,md5(confirm_password));
        if (res.code === 0) {
          this.$dialog.toast({
            mes: '绑定手机号成功',
            icon: 'success',
          });
          this.$router.replace("home");
        }
      }catch (e) {
        console.warn("Something bad happened: ", error);
      }
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#fff);

  .img-bg {
    .wh(100%, 3.64rem);
    .bis("../../assets/img_denglu_bg");
  }

  .tab-list {
    position: relative;
    .wh(100%, 1rem);
    margin-bottom: 0.4rem;
    overflow: hidden;

    li {
      .wh(50%, 100%);
      float: left;
      position: relative;
      overflow: hidden;
      z-index: 0;

      .btn {
        position: relative;
        .wh(100%, 100%);
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
        text-align: center;
      }

      &.active {
        .btn {
          .sc(@fontsize-large-x, @color-dark-grey);
          line-height: 0.5rem;
          font-weight: bold;

          &:after {
            contents: "";
            .cl;
            bottom: 0;
            display: block;
            .wh(2rem, 0.04rem);
            background-color: var(--main-color);
            border-radius: 0.03rem;
          }
        }
      }
    }
  }

  .title {
    padding-left: 0.52rem;
    margin-bottom: 0.18rem;
    .sc(@fontsize-large-xxx, @color-dark-grey);
    line-height: 0.66rem;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  .form {
    width: 100%;
    padding-left: 0.56rem;
    padding-right: 0.54rem;

    .form-group {
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 0.4rem;
      padding-bottom: 0.3rem;
      box-sizing: border-box;
      z-index: 1;

      .form-label {
        position: absolute;
        top: 0.44rem;
        left: 0;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        z-index: 4;

        .icon {
          display: inline-block;
          .wh(0.32rem, 0.32rem);
        }

        .icon-mobile {
          width: 0.24rem;
          .bis("../../assets/icon_denglu_shouji");
        }

        .icon-img-code {
          height: 0.28rem;
          .bis("../../assets/icon_denglu_yanzhengma");
        }

        .icon-sms-code {
          .bis("../../assets/icon_denglu_shuruyanzhengma");
        }

        .icon-password {
          width: 0.28rem;
          .bis("../../assets/icon_denglu_mima");
        }
      }

      .form-control {
        position: relative;
        .wh(100%, 98%);
        padding-left: 0.48rem;
        border: none;
        box-sizing: border-box;
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.4rem;
        transition: 0.3s;
        z-index: 3;

        &::-webkit-input-placeholder {
          color: #b3b3b3;
        }
      }

      .img-box {
        .ct;
        right: 0;
        .wh(1.2rem, 0.6rem);
        z-index: 4;

        .img-code {
          .wh(100%, 100%);
        }
      }

      .send-btn,
      .countdown {
        .ct;
        right: 0;
        .wh(1.68rem, 0.52rem);
        .sc(@fontsize-small, #fff);
        line-height: 0.52rem;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.26rem;
        z-index: 4;
      }

      .send-btn {
        background: var(--main-color);
        .touch-active;
      }

      .countdown {
        background: @color-light-grey;
      }
    }
  }

  .form-bottom {
    position: relative;
    display: flex;
    height: 0.34rem;
    padding-left: 0.56rem;
    padding-right: 0.54rem;
    margin-top: 0.2rem;
    margin-bottom: 0.58rem;

    .protocol-wrap {
      display: flex;
      align-items: center;

      .icon-wrap {
        .wh(0.28rem, 0.28rem);
        margin-right: 0.1rem;
      }

      .select-item {
        .wh(0.28rem, 0.28rem);
        color: @color-light-grey;
      }

      .selected-item {
        .wh(0.28rem, 0.28rem);
        color: var(--main-color);
      }

      .gray-txt {
        margin-right: 0.1rem;
        .sc(@fontsize-small, @color-dark-grey);
        line-height: 100%;
      }

      .protocol {
        .sc(@fontsize-medium, var(--main-color));
        line-height: 0.4rem;
      }
    }
  }

  .submit-btn {
    .wh(6.4rem, 0.88rem);
    margin: 0.8rem auto 0;
    .sc(@fontsize-large, #fff);
    line-height: 0.88rem;
    text-align: center;
    white-space: nowrap;
    background-color: var(--main-color);
    border-radius: 0.5rem;
    overflow: hidden;
    .touch-active(var(--main-color));
  }

  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    backface-visibility: hidden;

    .alert-content {
      .cl;
    }
  }

  .protocol-alert {
    .alert-content {
      top: 0.7rem;

      .protocol-container {
        .wh(6.7rem, 8.86rem);
        margin-bottom: 0.4rem;
        background-color: #fff;
        border-radius: 0.08rem;
        border: 0.04rem solid var(--main-color);
        overflow: hidden;

        .protocol-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          .wh(6.5rem, 8.66rem);
          padding: 0.3rem 0.2rem;
          background-color: #fff;
          border-radius: 0.08rem;
          border: 0.04rem solid var(--main-color);
          overflow: hidden;

          .top-title {
            display: flex;
            align-items: center;
            margin-bottom: 0.76rem;

            .dot {
              display: inline-block;
              .wh(0.08rem, 0.08rem);
              background-color: @color-light-grey;
              border-radius: 50%;
            }

            .txt {
              margin: 0 0.2rem;
              .sc(@fontsize-medium, @color-light-grey);
              line-height: 0.44rem;
              letter-spacing: -0.02rem;
            }
          }

          .protocol-title {
            margin-bottom: 0.6rem;
            .sc(@fontsize-large-x, var(--main-color));
            line-height: 0.5rem;
            letter-spacing: 0.01rem;
            font-weight: bold;
          }

          .protocol-content {
            max-height: 60%;
            .sc(0.26rem, @color-grey);
            line-height: 0.44rem;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
          }
        }
      }

      .close-btn {
        .wh(0.68rem, 0.68rem);
        margin: 0 auto;
        .bis("../../assets/icon_denglu_guanbi");
      }
    }
  }
}
</style>
